import React, { Component } from 'react';
import './events.scss';
import './calendar-style.scss';

class Events extends Component {
    render() {
        return (
            <div className="events-component">
                <div>
                    <div>
                        {/*<div className="text-danger" style={{*/}
                        {/*    fontSize: '22px',*/}
                        {/*    fontFamily: 'Lucida Calligraphy,Comic Sans MS,Lucida Console'*/}
                        {/*}}>News*/}
                        {/*</div>*/}

                        {/*<div className="news-body">*/}
                        {/*    /!*ライブ活動の再開に伴いライブご参加の方を対象にスタンプラリーを開催します。 <br/>*!/*/}
                        {/*    /!*その名も「Mikaグラーティアリレー2022」<br/>*!/*/}
                        {/*    /!*6/25の京橋から開始します<br/>*!/*/}
                        {/*    /!*是非プレゼント🎁をゲットして下さい<br/><br/>*!/*/}

                        {/*    /!*&#60;対象ライブ&#62;<br/>*!/*/}
                        {/*    /!*◎Mika主催ライブのチケット購入<br/>*!/*/}
                        {/*    /!*◎Mika主催ライブの配信チケット購入<br/>*!/*/}
                        {/*    /!*◎有料ブッキングライブのチケット購入<br/>*!/*/}

                        {/*    /!*&#60;景品&#62;<br/>*!/*/}
                        {/*    /!*スタンプが5ポイントでプレゼント有り🎁<br/><br/>*!/*/}

                        {/*    /!*(その他)<br/>*!/*/}
                        {/*    /!*○来場チケットと配信チケットをご購入頂いた時は2Pとなります<br/>*!/*/}
                        {/*    /!*○フリーライブは対象外となります<br/>*!/*/}
                        {/*    /!*○Mika主催のワンマンライブの来場チケットご購入はポイントが2倍となります<br/>*!/*/}
                        {/*    /!*○有効期限は2022年12月末のライブ迄<br/>*!/*/}
                        {/*    /!*○プライズの内容・回数等の変更・停止をすることがあります*!/*/}
                        {/*</div>*/}
                        {/*<hr/>*/}
                    </div>

                    {/*<div className="event">*/}
                    {/*    <i className="fas fa-calendar-check event-cal-icon"></i>*/}
                    {/*    <div className="event-title">*/}
                    {/*        2023/02/11㈯<br />*/}
                    {/*        BE MY VALENTINE 2マン Live<br />*/}
                    {/*        @Umeda Always <br />*/}

                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        2023 2.11 (Sat) Open 18:00　Start 18:30<br />*/}
                    {/*        会場チケット3,500円、当日4,000円、+1drink<br />*/}
                    {/*        配信3,000円 アーカイブ２週間<br />*/}
                    {/*        💝当日物販ご購入いただいた方全員にチョコプレゼント*/}
                    {/*    </div>*/}
                    {/*    <img src="/images/20230211_event.png" style={{width: '100%', marginTop: '10px'}}/> <br />*/}
                    {/*    <br />*/}
                    {/*    <a className="btn btn-green" target="_blank"*/}
                    {/*       href="https://docs.google.com/forms/d/e/1FAIpQLSdN5yq8pc_yiXpXd6qdT2zHJAa7vq1w42pw-9ZFgQA4-bozYA/viewform?usp=sf_link">会場チケットのご予約</a>*/}
                    {/*    <br /><br />*/}

                    {/*    <a className="btn btn-info" target="_blank"*/}
                    {/*       href="https://docs.google.com/forms/d/e/1FAIpQLSc8qcMq7egF3cD6oBRi5hhlfWnX0vLJi9Nt5l9r2sDeLugh7g/viewform?usp=sf_link">配信のご予約</a>*/}
                    {/*</div>*/}


                    {/* <div className="event">
                        <i className="fas fa-calendar-check event-cal-icon"></i>
                        <div className="event-title">
                            2024/06/23 (Sun) <br />
                            @枚方ビオルネ <br />
                        </div>
                        <div>
                            💝 観覧無料 💝
                        </div>
                        <div>
                            <img src="/images/184137.jpg" style={{ width: '100%', marginTop: '10px' }} /> <br />
                            <img src="/images/184139.jpg" style={{ width: '100%', marginTop: '10px' }} /> <br />
                        </div>
                    </div>

                    <div className="event">
                        <i className="fas fa-calendar-check event-cal-icon"></i>
                        <div className="event-title">
                            2024/07/13 (Sat)<br />
                            @古川橋そよら古川橋 <br />
                        </div>
                        <div>
                            💝 観覧無料 💝
                        </div>
                        <img src="/images/184135.jpg" style={{ width: '100%', marginTop: '10px' }} /> <br />
                        <br />
                    </div> */}

                    <div className="event">
                        <i className="fas fa-calendar-check event-cal-icon"></i>
                        <div className="event-title">
                            2024/09/21 (Sat)<br />
                            〜Mika Last 30th～<br />
                            @南港サンセットホール <br />

                        </div>
                        <div>
                            Open 16:00(前物販)<br />
                            Start 17:00<br /><br />

                            Ticket<br /><br />

                            <s>VIP席　最前列</s> <span className='text-danger'>Sold out</span><br />
                            ¥7.000<br />
                            ★15:15 先行入場<br />
                            ★特典あり🎁<br /><br />

                            一般　Ticket　¥4.000<br />
                            ※整理番号順入場自由席<br /><br />

                        </div>
                        <img src="/images/last-30.jpg" style={{ width: '100%', marginTop: '10px' }} /> <br />
                        <br />
                        <a className="btn btn-green" target="_blank"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSc7BmUAKUsMzB85Jm8TC8TTUd7vKEzeM4hadIXkhcTVNPLy4g/viewform?usp=sf_link">会場チケットのご予約</a>
                        <br /><br />
                    </div>

                    <div className="event">
                        <i className="fas fa-calendar-check event-cal-icon"></i>
                        <div className="event-title">
                            2024/11/02 (Sat)<br />
                            〜Mika First 40th～<br />
                            @ホテルニューオーサカ <br />
                            1F Café & Bar Adustam<br />
                        </div>
                        <div>
                            Open 18:00<br />
                            Start 18:30<br /><br />

                            Ticket<br /><br />

                            VIP席　最前列<br />
                            ¥13.000　Food&Drink込み <br /><br />

                            ★最前例席<br />
                            ★特典有り🎁<br /><br />

                            Tiket 一般　※テーブル指定席<br />
                            ¥8.000 Food&Drink込み<br />
                        </div>
                        <img src="/images/184143.jpg" style={{ width: '100%', marginTop: '10px' }} /> <br />
                        <br />
                        <a className="btn btn-green" target="_blank"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdDLH-FzRigTqdDLDChAaTNRQHa9r-CGDikf_9YmSZsIe6XwQ/viewform">会場チケットのご予約</a>
                        <br /><br />
                    </div>

                </div>
            </div>
        );
    }
}

export default Events;
